<template>
  <div>
    <div class="bb-container bb-container--lista">
      <portal-target name="alerts_ownerlist"></portal-target>
    </div>

    <div>
      <List :logoUrl="logoUrl" />
    </div>

    <transition name="bbFace" @after-enter="showSubscribedModalContent = true">
      <div class="modal" v-show="showSubscribedModal" @click="showContent = false">
        <transition name="bbUp" @after-leave="closeSubscribedModal()">
          <div
            class="modal__card modal__card--mini"
            v-show="showSubscribedModalContent"
            @click.stop=""
          >
            <div class="modal__dialog">
              <div class="modal__header">
                <div class="modal__close">
                  <img
                    src="../../assets/img/ico/ico-close.svg"
                    alt="Cerrar"
                    @click="showSubscribedModalContent = false"
                  />
                </div>
              </div>
              <div class="modal__content">
                <div class="card-success">
                  <div class="card-success__ico">
                    <img
                      src="../../assets/img/ico/ico-color-success.svg"
                      alt="¡Gracias!"
                    />
                  </div>
                  <h3 class="card-success__header">Pago recibido</h3>
                  <div class="card-success__content">
                    Ya puedes disfrutar de HelloBB sin publicidad.
                  </div>
                  <div class="card-success__actions">
                    <a
                      href="#"
                      @click="showSubscribedModalContent = false"
                      class="button button--primary button--w60"
                      >Genial</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </transition>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import NoSSR from "vue-no-ssr";

import Features from "../../Mixins/Features";
import CanGetOwnerList from "../../Mixins/CanGetOwnerList";

import CustomizableIframe from "./mixins/CustomizableIframe";
import List from "./components/List";

export default {
  name: "ListPage",
  mixins: [
    CanGetOwnerList,
    Features,
    CustomizableIframe,
  ],
  components: {
    List,
    "no-ssr": NoSSR,
  },
  metaInfo() {
    return {
      title: this.title + " | HelloBB",
      meta: [{ name: "robots", content: "noindex" }],
    };
  },
  data() {
    return {
      showSubscribedModal: false,
      showSubscribedModalContent: false,
    };
  },
  computed: {
    ...mapGetters({
      limit: "limit",
    }),
    showListWizard() {
      if (this.list != null) {
        if (this.list.products != null) {
          if (this.list.products.length > 0) return true;
        }
      }
      return false;
    },
    title() {
      if (this.list.title) return this.list.title;
      if (this.list.ownerName) return "La lista de " + this.list.ownerName;
      return "Lista sín título";
    },
    description() {
      return this.title;
    },
    isApp() {
      return this.$store.state.cookies.sourceApp;
    },
    logoUrl() {
      return this.customizations?.images?.logoUrl || "";
    },
  },
  methods: {
    ctaFixedBannerClosed() {
      this.isCtaFixedBannerVisible = false;
    },
    trackClick() {
      alert(this.$refs.ctaMainBanner.Name);
    },

    async openSubscribedModal(checkoutSessionId) {
      const {
        paymentStatus,
        type,
      } = await this.$store.dispatch("getCheckoutSessionId", {
        checkoutSessionId,
      });

      const isValidCheckoutSessionId = (
        type === "upgrade" &&
        paymentStatus === "paid"
      );
      if (!isValidCheckoutSessionId) {
        console.error("Invalid checkout session ID.");

        this.$router.replace({ path: "/mi-lista" });

        return;
      }

      await this.$store.dispatch("updateUserRoleInSession", "Premium");

      document.body.classList.add("modal-open");
      this.showSubscribedModal = true;
    },
    closeSubscribedModal() {
      document.body.classList.remove("modal-open");
      this.showSubscribedModal = false;

      // This removes the query param, so if the user refreshes the page, the
      // modal is not shown again.
      this.$router.replace({ path: "/mi-lista" });
    },
  },
  created() {
    const filtersQS = this.$route.query.filters;
    if (typeof filtersQS === "string" || filtersQS instanceof String) {
      const filters = JSON.parse(filtersQS);
      this.$setOwnerProductFilters(filters, false);
    } else {
      this.$setOwnerProductFilters([], false, false);
    }
  },
  async mounted() {
    await this.$store.dispatch("setLimitSectionsToShow");

    window.vm = {};
    vm.someComponent = this;

    const checkoutSessionId = this.$route.query?.session_id;
    if (checkoutSessionId) {
      this.$nextTick(async () => {
        await this.openSubscribedModal(checkoutSessionId);
      });
    }
  },
};
</script>
