<template>
  <section
    class="pad-t-0"
    v-show="!hideFullPage && !!customizations"
    :class="[hideFullPage ? 'hide-full-page' : '', showModalAsPage ? 'full-page-modal-plugin' : '']"
  >
  <no-ssr>

    <ModalDialog
      :showLogout="loaded"
      :logoUrl="logoUrl"
      :show="showModalAddProduct"
      @close="close()"
      :target-class="(onboardingStep === -1 ? ' modal__card--wide-desktop-only' : '')"
      @logout="handleLogout()"
    >
      <OnboardingModal
        v-show="onboardingStep !== -1"
        :step="onboardingStep"
        @close="close()"
        @step="setOnboardingStep"
      />
      <AddProductModal
        v-show="onboardingStep === -1"
        @close="close()"
        @update="update"
        @productAdded="showModalProductAdded = true"
      />
    </ModalDialog>

    <ModalDialog
      :logoUrl="logoUrl"
      :show="loaded && !isPopupWindow && showModalProductAdded"
      :showLogout="false"
      @close="close()"
      target-class=""
      @logout="handleLogout()"
    >
      <ProductAddedToList
        @close="close()"
        :listUrl="listUrl"
        :isPopup="showModalAsPage"
      />
    </ModalDialog>

    <ModalDialog
      :logoUrl="logoUrl"
      :show="loaded && !isPopupWindow && askUserToLogin"
      :showLogin="isSignup"
      :showSignup="!isSignup"
      @close="close()"
      @goToLogin="goToLogin()"
      @goToSignup="goToSignup()"
      @logout="handleLogout()"
      target-class="modal__card--small"
    >
      <LoginWaitModal
        v-show="popupIsOpen"

        @close="close()"
        @loggedIn="handleLoggedIn()"
      />
      <LoginModal
        v-show="!popupIsOpen && !isSignup"

        @close="close()"
        @loggedIn="handleLoggedIn()"
        @popup="popupIsOpen = true"
      />
      <SignupModal
        v-show="!popupIsOpen && isSignup"

        @close="close()"
        @loggedIn="handleLoggedIn()"
        @popup="popupIsOpen = true"
        @signedUp="handleSignedUp()"
        @goToLogin="goToLogin()"
      />
    </ModalDialog>

  </no-ssr>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import vClickOutside from "v-click-outside";
import NoSSR from "vue-no-ssr";

import LoadingButton from "../../components/LoadingButton";

import CanGetCategories from "../../Mixins/CanGetCategories";
import CanGetOwnerList from "../../Mixins/CanGetOwnerList";
import CanLogout from "../../Mixins/CanLogout";
import FullPageModal from "../../Mixins/FullPageModal";
import MustHaveToggleIntern from "../ownerlist/components/MustHaveToggleIntern";

import AddProductModal from "./components/AddProductModal";
import CustomizableIframe from "./mixins/CustomizableIframe";
import LoginModal from "./components/LoginModal";
import LoginWaitModal from "./components/LoginWaitModal";
import ModalDialog from "./components/ui/ModalDialog";
import OnboardingModal from "./components/OnboardingModal";
import ProductAddedToList from "./components/ProductAddedToList";
import SignupModal from "./components/SignupModal";

export default {
  name: "ModalIframe",
  mixins: [
    CanGetOwnerList,
    CanGetCategories,
    FullPageModal,
    CustomizableIframe,
    CanLogout,
  ],
  components: {
    VueSlickCarousel,
    MustHaveToggleIntern,
    LoadingButton,
    ModalDialog,
    ProductAddedToList,
    LoginModal,
    LoginWaitModal,
    "no-ssr": NoSSR,
    SignupModal,
    AddProductModal,
    OnboardingModal,
  },
  metaInfo() {
    return {
      title: `Añadir objeto | HelloBB`,
    };
  },
  data() {
    return {
      loaded: false,
      popupHasBeenOpened: false,
      preview: false,
      errorMsg: null,
      showSlider: false,
      mustHave: false,
      showModalProductAdded: false,
      showModalPopup: false,
      error: false,
      parsing: false,
      parsedIncomplete: false,
      urlFromParser: "",
      name: "",
      nameDirty: false,
      url: "",
      imageUrl: "",
      urlDirty: false,
      price: "",
      priceDirty: false,
      userComments: "",
      categorySelected: null,
      categoryDirty: false,
      saving: false,
      askUserToLogin: false,
      isSignup: false,
      onboardingStep: -1,
      popupIsOpen: false,
      config: {
        handler: this.closeCategories,
        events: ["dblclick", "click"],
      },
      toggleCategories: false,
      productPhotos: [],
      productUploadedPhotos: [],
      currentIndex: 0,
      errorNotLoggedIn: false,
      settings: {
        draggable: false,
        centerMode: false,
        slidesToShow: 2,
        slidesToScroll: 2,
        rows: 3,
        arrows: true,
        infinite: false,
        slidesPerRow: 1,
        responsive: [
          {
            breakpoint: 767,
            settings: {
              centerMode: true,
              rows: 1,
              slidesToShow: 1,
              slidesToScroll: 2,
              // slidesPerRow: 1,
              infinite: false,
              dots: true,
              arrows: false,
            },
          },
        ],
      },
    };
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  computed: {
    ...mapGetters({
      categories: "categories",
      listActive: "listActive",
      isServer: "isServer",
    }),
    showModalAsPage() {
      return this.$route.query.mode === "limited";
    },
    showModalAddProduct() {
      return !this.showModalProductAdded && !this.showModalPopup && !this.askUserToLogin;
    },
    defaultPhotoIndex() {
      let x = this.productPhotos.indexOf(this.imageUrl);
      if (x === -1) {
        return (
          this.productPhotos.length +
          this.productUploadedPhotos.indexOf(this.imageUrl) +
          1
        );
      }
      return x + 1;
    },
    images() {
      return this.$utils.removeDuplicates([
        ...this.productUploadedPhotos,
        ...this.productPhotos,
      ]);
    },
    disabled() {
      if (
        this.name !== "" &&
        this.url !== "" &&
        this.price !== "" &&
        this.price !== "-1" &&
        !isNaN(this.price.replace(",", "."))
      ) {
        return false;
      }
      return true;
    },
    categoryLabel() {
      const category = this.categories.find((category) => category.id === this.categorySelected);
      let translatedName = "";
      const categoryId = category?.id;
      if (categoryId) {
        translatedName = this.getTranslatedCategory(categoryId);
      }
      return translatedName || category?.name || "";
    },
    nameHasError() {
      return this.name === "" && this.nameDirty;
    },
    priceHasError() {
      return (this.price == "" || this.price == -1) && this.priceDirty;
    },
    categoryHasError() {
      return this.categorySelected === null && this.categoryDirty;
    },
    translatedCategories() {
      return this.categories.map((category) => ({
        ...category,

        name: this.getTranslatedCategory(category.id) || category.name,
      }));
    },
    hideFullPage() {
      return this.showModalProductAdded || this.askUserToLogin || this.showModalPopup;
    },
    isPopupWindow() {
      return this.$route.query.popup === "1";
    },
    showOnboarding() {
      return this.$route.query.showOnboarding === "1";
    },
    productId() {
      return this.$route.query.product || "";
    },
    logoUrl() {
      return this.customizations?.images?.logoUrl || "";
    },
    listUrl() {
      return this.customizations?.listUrl || "/mi-lista";
    },
    hasWantedRoute() {
      return !!this.$route.query.wantedRoute;
    },
  },
  methods: {
    close() {
      try {
        window.close();
      } catch (err) {
        // Ignore.
      }

      try {
        this.sendMessageToParentWindow("close");
      } catch (err) {
        // Ignore.
      }
    },
    sendMessageToParentWindow(type, payload = null) {
      window.parent.postMessage({
        source: "hellobb",
        type,
        payload,
      }, "*");
    },
    async sendOpenPopupMessage() {
      const url = window.location.href + "&popup=1";

      this.sendMessageToParentWindow("popup", {
        url,
        width: 600,
        height: 380,
      });

      await this.watchPopupCookie();

      this.sendMessageToParentWindow("reload", {
        ...this.$route.query,
      });
    },
    async watchPopupCookie(timeout = 10 * 60 * 1000) {
      const stopAfter = Date.now() + timeout;
      while (Date.now() < stopAfter && !this.popupHasBeenOpened) {
        this.popupHasBeenOpened = await this.$store.dispatch("getPopupCookie");
        if (this.popupHasBeenOpened) {
          return true;
        }

        await this.$sleep(() => {
          // Do nothing.
        }, 1_000);
      }

      return false;
    },
    toggle() {
      this.toggleCategories = !this.toggleCategories;
    },
    applyCategory(category) {
      this.categorySelected = category.id;
      this.toggleCategories = false;
    },
    closeCategories() {
      this.toggleCategories = false;
    },
    nameBlur() {
      this.nameDirty = true;
    },
    urlBlur() {
      this.urlDirty = true;
    },
    priceBlur() {
      this.priceDirty = true;
    },
    getList() {
      return this.$store.dispatch("getOwnerList");
    },
    async isLoggedIn() {
      try {
        const connectedUser = await this.$store.dispatch("getConnectedUser");

        return connectedUser.id > 0;
      } catch (err) {
        console.error(err);

        return false;
      }
    },
    update(data) {
      [
        "askUserToLogin",
        "showModalPopup",
        "loaded",
        "isSignup",
      ].forEach((key) => {
        if (Object.hasOwn(data, key)) {
          console.log(`Setting ${key} to ${data[key]}`);
          this[key] = data[key];
        }
      });
    },
    setOnboardingStep(index) {
      // If the index is invalid, the `OnboardingModal` component will catch
      // the issue during mount and emit an event to set the index to `-1`.
      this.onboardingStep = index;
    },
    async handleLogout() {
      await this.logout(false);

      if (this.showModalAsPage) {
        window.location.reload();

        return;
      }

      this.sendMessageToParentWindow("reload", {
        ...this.$route.query,
      });
    },
    handleLoggedIn() {
      if (this.showModalAsPage) {
        window.location.reload();

        return;
      }

      this.sendMessageToParentWindow("reload", {
        ...this.$route.query,
      });
    },
    handleSignedUp() {
      if (this.showModalAsPage) {
        const url = window.location.href + "&showOnboarding=1";

        window.location.href = url;

        return;
      }

      this.sendMessageToParentWindow("reload", {
        ...this.$route.query,

        showOnboarding: "1",
      });
    },
    async goToLogin() {
      if (this.hasWantedRoute) {
        const queryString = Object.keys(this.$route.query)
          .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(this.$route.query[key])}`)
          .join("&")

        window.location.href = "/external-ecommerce/login?" + queryString;
      } else {
        this.isSignup = false;
      }
    },
    goToSignup() {
      if (this.hasWantedRoute) {
        const queryString = Object.keys(this.$route.query)
          .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(this.$route.query[key])}`)
          .join("&")

        window.location.href = "/external-ecommerce/signup?" + queryString;
      } else {
        this.isSignup = true;
      }
    },
  },
  beforeMount() {
    if (this.showOnboarding) {
      this.onboardingStep = 0;
    }
  },
};
</script>

<style scoped>
.slick-next::before {
  color: black;
}

.slick-prev::before {
  color: black;
}
.circle-imatge {
  width: 12px;
  height: 12px;
  margin-right: 15px;
  padding-right: 0px;
  padding-bottom: 0px;
  border-radius: 60px;
  background-color: #ffbd59;
}

.circle-imatge.gris {
  width: 12px;
  height: 12px;
  margin-right: 15px;
  padding-right: 0px;
  padding-bottom: 0px;
  border-radius: 60px;
  background-color: #dcdcdc;
}

.circle-imatge.gris.darrer {
  margin-right: 0px;
}
</style>
