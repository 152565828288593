<template>
  <div>
    <div class="p404 bg-cercles">
      <div class="p404__content">
        <img
          class="p404__favicon"
          src="../../assets/img/logos/favicon.svg"
          alt=""
        />
        <h2 class="p404__title">{{ $t("login.social.successHeader") }}</h2>
        <p>
          {{ $t("login.social.successDescription") }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SocialLoginSuccess",
  metaInfo() {
    return {
      title: this.$t("login.metaTitle") + " | HelloBB",
      meta: [
        { name: "description", content: this.$t("login.metaDescription") },
        { name: "robots", content: "noindex"},
      ]
    };
  },
  computed: {
    isNewUser() {
      return this.$route.query.newuser === "true";
    },
  },
  async beforeMount() {
    // Try to close the tab/popup before it even mounts.
    //
    // If this fails, we'll still show a fallback page.
    window.close();
  },
};
</script>

<style scoped>
p {
  font-size: 1.2rem;
  line-height: 1.5;
  max-width: 60%; /* O el ancho máximo que prefieras */
  margin: 0 auto; /* Esto centra el contenedor horizontalmente */
}

@media (max-width: 768px) {
  p {
    font-size: 1rem; /* Tamaño de fuente más pequeño para móviles */
    line-height: 1.4; /* Ajuste del interlineado para mejorar la legibilidad en pantallas pequeñas */
    max-width: 90%;
  }
}
</style>
