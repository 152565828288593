<template>
  <div class="card-success">
    <div class="card-success__ico">
      <img
        src="../../../assets/img/ico/ico-color-success.svg"
        alt="¡Felicidades!"
      />
    </div>
    <h3 class="card-success__header">{{ $t("freeAdd.extensionProductTitle") }}</h3>
    <div class="card-success__content">
      {{ $t("freeAdd.extensionProductSubtitle") }}
    </div>
    <div class="card-success__actions card-success__actions--vertical card-success__actions--50">
      <a
        v-if="listUrl"
        :href="listUrl"
        class="button button--primary"
        target="_blank"
        @click.prevent="openList()"
      >
        {{ $t("freeAdd.extensionProductButton") }}
      </a>
      <a
        v-else
        href="/mi-lista"
        class="button button--primary"
        target="_blank"
      >
        {{ $t("freeAdd.extensionProductButton") }}
      </a>
      <a
        @click.prevent="$emit('close')"
        href="#"
        class="button button--secondary"
      >
        {{ isPopup ? $t("external.popupClose") : $t("generic.close") }}
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import WindowMessages from "../../../Mixins/WindowMessages";

export default {
  name: "ProductAddedToList",
  mixins: [
    WindowMessages,
  ],
  props: {
    listUrl: {
      type: String,
      default: "/mi-lista",
    },
    isPopup: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    openList() {
      if (this.listUrl === "/mi-lista") {
        const url = "https://www.hellobb.net/mi-lista";
        if (this.isPopup) {
          window.open(url, "_blank")

          return;
        }

        this.sendMessageToParentWindow("openNewTab", {
          url,
        });
      }
      else {
        const url = this.listUrl;
        if (this.isPopup) {
          window.location.href = url;

          return;
        }

        this.sendMessageToParentWindow("replaceTab", {
          url,
        });
      }
    },
  },
};
</script>
