<template>
  <div>

      <component
        v-if="isValidStep && stepComponent"
        :is="stepComponent"
        @step="setStep"
      />

  </div>
</template>

<script>
// TODO: Move files from the `onboarding` module to this module.
import OnboardingSteps from "../../onboarding/mixins/Steps";
import OnboardingStepEcommerceBirthdateContent from "../../onboarding/components/OnboardingStepEcommerceBirthdateContent";

export default {
  name: "OnboardingModal",
  components: {
    OnboardingStepEcommerceBirthdateContent,
  },
  props: {
    step: {
      type: Number,
      required: true,
    },
  },
  mixins: [
    OnboardingSteps,
  ],
  computed: {
    isValidStep() {
      return this.step >= 0 && this.step < this.allSteps.length;
    },
    stepComponent() {
      return this.allSteps[this.step]?.componentName;
    },
  },
  methods: {
    setStep(step) {
      this.$emit("step", step);
    },
  },
  beforeMount() {
    if (!this.isValidStep) {
      this.setStep(-1);

      return;
    }
    if (!this.stepComponent) {
      this.setStep(-1);

      return;
    }
  },
}
</script>
