<template>
  <div class="card-success">
    <h3 class="card-success__header">{{ $t("freeAdd.extensionLoginTitle") }}</h3>
    <div class="card-success__content">
      {{ $t("freeAdd.extensionLoginSubtitleClicked") }}
    </div>
    <div class="card-success__actions card-success__actions--vertical card-success__actions--50">
      <a
        @click.prevent="$emit('close')"
        href="#"
        class="button button--secondary"
      >
        {{ $t("generic.close") }}
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "LoginWaitModal",
};
</script>
