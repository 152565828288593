<template>
  <section v-show="!!customizations">
    <button
      type="button"
      class="button button--block button--primary button--plugin"
      @click="addToHelloBB()"
      :style="buttonStyle"
    >
      {{ $t("external.addToHelloBB") }}
    </button>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

import BrowserDetection from "../../Mixins/BrowserDetection";

import CustomizableIframe from "./mixins/CustomizableIframe"

export default {
  name: "ButtonIframe",
  mixins: [
    CustomizableIframe,
    BrowserDetection,
  ],
  computed: {
    ...mapGetters({
    }),
    productId() {
      return this.$route.query.product || "";
    },
    buttonStyle() {
      if (!this.customizations) {
        return {};
      }

      return {
        "backgroundColor": this.customizations.styles.button.backgroundColor,
        "border": `${this.formatCssSize(this.customizations.styles.button.borderWidth, "1px")} solid ${this.customizations.styles.button.borderColor || "transparent"}`,
        "color": this.customizations.styles.button.textColor,
        "borderRadius": `${this.customizations.styles.button.borderRadius}px`,
        "fontFamily": this.customizations.styles.button.fontFamily,
        "fontSize": `${this.customizations.styles.button.fontSize}px`,
        "paddingTop": this.formatCssSize(this.customizations.styles.button.paddingTop),
        "paddingRight": this.formatCssSize(this.customizations.styles.button.paddingRight),
        "paddingBottom": this.formatCssSize(this.customizations.styles.button.paddingBottom),
        "paddingLeft": this.formatCssSize(this.customizations.styles.button.paddingLeft),
      };
    },
  },
  methods: {
    formatCssSize(value, defaultValue = "auto") {
      if (value === null) {
        return defaultValue;
      }

      return `${value}px`;
    },
    addToHelloBB() {
      const extraParams = {};
      if (this.browserHasIssuesWithThirdPartySession) {
        extraParams.mode = "limited";
      }

      this.sendMessageToParentWindow("openAddToHelloBBModal", {
        ...(this.$route.query || {}),
        ...extraParams,
      });
    },
  },
};
</script>

<style scoped>
</style>
