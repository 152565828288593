import WindowMessages from "../../../Mixins/WindowMessages";

export default {
    mixins: [
        WindowMessages,
    ],
    data() {
        return {
            customizations: null,
            customStylesheetElement: null,
        };
    },
    async beforeMount() {
        const clientId = this.$route.query.client_id || "";
        if (clientId) {
            this.customizations = await this.$store.dispatch("getEcommerceCustomizations", clientId);
        } else {
            this.sendMessageToParentWindow("getCustomizations");
        }
    },
    methods: {
        sendMessageToParentWindow(type, payload = null) {
            window.parent.postMessage({
                source: "hellobb",
                type,
                payload,
            }, "*");
        },
        onMessageSetCustomizations(message) {
            this.customizations = message.payload;
        },
        onMessageCss(message) {
            console.log({ message });
            const el = this.customStylesheetElement;
            if (!el) {
                return;
            }
            el.textContent = message.payload;
        }
    },
    mounted() {
        const style = document.createElement("style");
        style.setAttribute("type", "text/css");
        document.body.appendChild(style);

        this.customStylesheetElement = style;
    },
    unmounted() {
        if (this.customStylesheetElement) {
            this.customStylesheetElement.remove();
        }
    },
}
